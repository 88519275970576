import React, { useEffect, useState } from "react";
import "./App.scss";
import AboutMe from "./pages/aboutme";
import { Page, Info, pages, PAGES, CardNature, Section } from "./commons";
import Resume from "./pages/resume";
import Contact from "./pages/contact";
import Projects from "./pages/projects";
import { getRequest } from "./utils/request";

function App() {
  const [selectedPage, setSelectedPage] = useState<Page>(PAGES.ABOUT_ME);
  const [info, setInfo] = useState<Info>();
  const [sections, setSections] = useState<Section[]>([]);
  const [menuOpened, setMenuOpened] = useState<boolean>(false);

  const selectPage = (pg: Page) => {
    setSelectedPage(pg);
    setMenuOpened(false);
  };

  const retrieveInfo = () => {
    getRequest("/info").then((data) => {
      setInfo(data);
    });
  };

  const retrieveSections = () => {
    getRequest("/sections").then((data) => setSections(data));
  };

  useEffect(() => {
    retrieveInfo();
    retrieveSections();
  }, []);

  const getPage = () => {
    let result = null;
    if (info) {
      switch (selectedPage.id) {
        case PAGES.RESUME.id:
          result = <Resume info={info} sections={sections} />;
          break;
        // case PAGES.PROJECTS.id:
        //   result = <Projects />;
        //   break;
        case PAGES.CONTACT.id:
          result = <Contact />;
          break;
        default:
          result = <AboutMe info={info} selectPage={selectPage} />;
          break;
      }
    }

    return result;
  };

  const getPagesRender = () => {
    return (
      <>
        {pages.map((pg) => (
          <button
            className={selectedPage.id == pg.id ? "selected_page" : ""}
            onClick={() => selectPage(pg)}
            key={pg.id}
          >
            {pg.name}
          </button>
        ))}
      </>
    );
  };

  return (
    <>
      {menuOpened ? (
        <div className="menu_pages">
          {getPagesRender()}
          <button onClick={() => setMenuOpened(false)}>X</button>
        </div>
      ) : null}
      <div className={!menuOpened ? "App" : "AppMenu"}>
        <header>
          <div className="name_title">
            <div className="name">
              <div className="point_box">
                <div className="point"></div>
              </div>
              <span className="name_text">{info?.name}</span>
            </div>
            <div className="separator">/</div>
            <div className="title">{info?.title}</div>
          </div>
          <div className="burger">
            <div className="burger_box" onClick={() => setMenuOpened(true)}>
              {[0, 0, 0, 0].map((item) => (
                <div className="burger_item"></div>
              ))}
            </div>
          </div>
          <div className="pages">{getPagesRender()}</div>
        </header>
        {getPage()}
        <footer>
          <div className="copyright">
            <span className="value">
              © {info?.copyright?.year} by {info?.name}
            </span>
          </div>
          <div className="contact">
            {/* <div className="item">
              <span className="title">Phone</span>
              <span className="value">{info?.phone}</span>
            </div> */}
            <div className="item">
              <span className="title">Email</span>
              <span className="value">{info?.email}</span>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

export default App;
